<template>
  <!--  <div-->
  <!--    class="offer-item__left__header__status status"-->
  <!--    :class="{'status_active': status === selectedStatus}"-->
  <!--    @click="$emit('select', status)"-->
  <!--  >-->
  <!--    <span class="status__left">-->
  <!--      {{ status.label }}-->
  <!--    </span>-->
  <!--    <span class="status__right">-->
  <!--      {{ offer[status.value] }}-->
  <!--      <span-->
  <!--        v-if="!status.value"-->
  <!--        class="status__right__new"-->
  <!--      > + 1 </span>-->
  <!--    </span>-->
  <!--  </div>-->
  <AppButton
    :custom-style="{'box-shadow': 'none !important'}"
    style="display: block !important; width: 100%; text-align: left !important;"
    class="offer-item__left__header__status status custom-btn-wrapper"
    :class="{'status_active': status === selectedStatus}"
    @click.native="$emit('select', status)"
  >
    <span
      style="display: inline-block; width: 94%"
      class="status__left"
    >
      {{ status.label }}
    </span>
    <span class="status__right">
      {{ offer[status.value] }}
      <span
        v-if="!status.value"
        class="status__right__new"
      > + 1 </span>
    </span>
  </AppButton>
</template>

<script>
import AppButton from '@/components/common/simple/AppButton'

export default {
  name: 'OfferItemUserStatus',
  components: { AppButton },
  props: {
    status: {
      type: Object,
      required: true,
    },
    selectedStatus: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
  .status
    cursor: pointer
    &:hover &__left
      color: rgba(0, 0, 0, 0.51)
    &_active &__left
      color: black
    &__left
      @include fontStyle(600, 16px)
      color: rgba(0, 0, 0, 0.4)
      &_active
        color: black
    &__right
      @include fontStyle(600, 16px)
      color: rgba(0, 0, 0, 0.3)
      margin-left: auto
      &__new
        color: #8BD118
</style>
