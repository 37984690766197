<template>
  <AppMainContainer
    v-if="offer"
    center-class="offer-item"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <v-expansion-panels
        v-model="panelEnabled"
        :multiple="false"
      >
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span v-if="panelEnabled === 0" />
            <OfferItemHeaderPreview
              v-else
              :selectedUser="selectedUser"
              :offer="offer"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <PageHeader
              :showOrganizationBalance="false"
              :isAdmin="iAmOrganizationRoot || iAmOrganizationAdmin"
            />
            <OfferItemHeader
              id="offer-header-container"
              :offer="offer"
              :selectedUser="selectedUser"
              @cancelOffer="OncancelOffer"
              @reloadOffer="reloadOffer"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div style="margin-top: 16px;" />

      <div
        v-if="serverError"
        style="text-align: center"
      >
        <div style="margin: auto; color: whitesmoke;padding: 16px; background-color: darkred">
          <p
            style="float: right;
            cursor: pointer;
    margin-right: 8px;
    border: 1px solid white;
    border-radius: 50%;
    width: 21px;
    height: 21px;"
            @click="toggleServerErrorDisplay"
          >
            X
          </p>
          <p style="padding-right: 32px">
            {{
              errorCode === 409 ? 'Невозможно пригласить этого соискателя так как он уже записался на другую смену' :
              'Ошибка при выполнении запроса! повторите запрос или обратите в поддержку!'
            }}
          </p>
        </div>
      </div>
    </template>

    <template #center>
      <div
        class="offer-item"
      >
        <div class="offer-item__left">
          <div class="offer-item__left__header">
            <OfferItemUserStatus
              v-for="status in offerRespondentStatus"
              :key="status.label"
              :offer="offer"
              :status="status"
              :selected-status="selectedStatus"
              @select="selectStatus"
            />
          </div>

          <div
            v-if="respondents.length"
            class="offer-item__left__list-user"
          >
            <OfferItemChatUserCard
              v-for="respondent in respondents"
              :key="respondent.id"
              :response-laborer="respondent"
              :selected-user="selectedUser"
              :modelType="selectedStatus"
              :isLoading="isLoading"
              @click="selectLaborer"
              @accept="accept"
              @reject="onOfferItemChatUserCardReject"
            />
          </div>

          <div
            v-else
            class="offer-item__left__list-user offer-item__left__list-user_empty"
          >
            {{ offer.status === 'active' || offer.status === 'casting' ? 'Откликов пока нет' : 'Оффер закрыт' }}
          </div>
        </div>

        <div
          :style="panelEnabled ? undefined :{minHeight: '540px'} "
          class="offer-item__right"
        >
          <OfferItemChat
            :selected-user="selectedUser"
            :respondents="respondents"
            :offer="offer"
            :selectedStatus="selectedStatus"
            :isLoading="isLoading"
            @accept="accept"
            @reject="currentModal = modalName.rejectLaborer"
          />
        </div>
      </div>
    </template>

    <template #modal>
      <AppModalSmall
        v-if="currentModal === modalName.cancelOffer"
        style="padding: 32px 26px; max-width: 320px"
      >
        <h1 class="modal__header">
          {{ `Вы уверены, что хотите отменить ${cancelOfferNumber} ${pluriaizeOffers(cancelOfferNumber)}` }}
        </h1>

        <div
          style="display: flex; justify-content: center; align-items: center"
          class="modal__button"
        >
          <IconMinus
            style="cursor: pointer"
            @click="onCancelNumberChange(false)"
          />
          <span style="display: inline-block; padding-left: 5px; padding-right: 5px">
            {{ ` ${cancelOfferNumber} ${pluriaizeOffers(cancelOfferNumber)}` }} </span>
          <IconPlusSmall
            style="cursor: pointer"
            :fill="cancelOfferNumber === offer.childrenNumber ? '#0000004d' : '#8BD118'"
            @click="onCancelNumberChange(true)"
          />
        </div>

        <div class="cancel-description">
          <span style="font-weight: 900; font-size: 1.1em; ">
            {{ `Не более ${offer.childrenNumber} ${pluriaizeOffers(cancelOfferNumber)}` }}
          </span>
        </div>

        <div
          class="modal__button"
        >
          <AppLoader v-if="isLoading" />
          <AppButton
            v-else
            class="button__warm-action"
            @click.native="cancelOffer"
          >
            Отменить офферы
          </AppButton>
        </div>

        <a
          v-if="!isLoading"
          class="modal__button-cancel"
          @click.prevent="currentModal = ''"
        >Отмена</a>
      </AppModalSmall>

      <AppModalSmall
        v-if="currentModal === modalName.acceptLaborer"
        style="padding: 16px; width: 400px;"
      >
        <h1 class="modal__header">
          Вы пригласили соискателя!
        </h1>

        <p class="modal__description">
          Мы ожидаем его ответа для закрытия оффера.
        </p>
        <p class="modal__description">
          Остальные соискатели получат автоматическое
          сообщение об отказе.
        </p>

        <div class="modal__button">
          <AppButton
            class=""
            @click.native="currentModal = ''"
          >
            Ок
          </AppButton>
        </div>
      </AppModalSmall>

      <AppModalSmall
        v-if="currentModal === modalName.rejectLaborer"
        style="padding: 16px; width: 400px"
      >
        <h1 class="modal__header">
          Вы уверены, что хотите отказать соискателю?
        </h1>

        <div
          class="modal__button"
          style="margin-top: 12px"
        >
          <AppButton
            class="button__warm-action"
            @click.native="reject(selectedUser)"
          >
            Отказать
          </AppButton>
        </div>

        <a
          style="margin-top: 12px"
          class="modal__button-cancel"
          @click.prevent="currentModal = ''"
        >Отмена</a>
      </AppModalSmall>
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferItemHeader from '@/components/offer/OfferItemHeader'
import OfferItemHeaderPreview from '@/components/offer/OfferItemHeaderPreview'
import OfferItemUserStatus from '@/components/offer/OfferItemUserStatus'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import OfferItemChat from '@/components/offer/OfferItemChat'
import AppModalSmall from '@/components/common/modal/AppModalSmall'
import AppButton from '@/components/common/simple/AppButton'
import AppLoader from '@/components/AppLoader'
import IconPlusSmall from '@/components/common/icons/IconPlusSmall'
import IconMinus from '@/components/common/icons/IconMinus'
import { pluriaizeOffers } from '@/utils/plural-util'
import routerList from '@/router/labels'
import AppOfferUtil from '@/utils/offerUtil'
import PageHeader from '@/components/subheaders/PageHeader.vue'

const modalName = {
  cancelOffer: 'cancelOffer',
  acceptLaborer: 'acceptLaborer',
  rejectLaborer: 'rejectLaborer',
}

export default {
  name: 'PageOfferItem',
  components: {
    AppButton,
    PageHeader,
    AppLoader,
    IconPlusSmall,
    IconMinus,
    AppModalSmall, OfferItemChat,
    OfferItemChatUserCard, OfferItemUserStatus,
    OfferItemHeader,
    OfferItemHeaderPreview,
    AppMainContainer
  },
  props: {
    offerId: {
      type: String
    }
  },
  data () {
    const offerRespondentStatus = [
      { label: 'Отклики', value: 'respondNumber' },
      { label: 'Приглашения', value: 'invitationNumber' },
      { label: 'Отказы', value: 'rejectedNumber' },
    ]

    return {
      serverError: false,
      serverErrorDisplayTimeOut: 8000,
      chatStyle: null,
      errorCode: null,
      isLoading: false,
      cancelOfferNumber: 1,
      offer: null,
      selectedStatus: offerRespondentStatus[0],
      selectedUser: null,
      panelEnabled: null,
      // respondents: [],
      offerRespondentStatus,
      currentModal: '',
      modalName
    }
  },

  computed: {
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    respondents () {
      return this.$store.state.offer.offerLaborerList
    }
  },

  watch: {
    selectedStatus (newValue) {
      this.$store.commit('offer/offerLaborerList', [])
      this.selectedUser = null
      this.$store.commit('offer/setCurrentOfferLaborer', null)
      if (newValue.value === 'respondNumber') {
        this.fetchOfferRespondent(true)
        // .then((response) => {this.respondents = response.data})
      } else if (newValue.value === 'invitationNumber') {
        this.fetchOfferInvitationsRespondent(true)
        // .then((response) => {this.respondents = response.data})
      } else if (newValue.value === 'rejectedNumber') {
        this.fetchOfferRespondent(false)
        // .then((response) => {this.respondents = this.respondents.concat(response.data)})
        // this.fetchOfferInvitationsRespondent(false)
        //   .then((response) => {this.respondents = this.respondents.concat(response.data)})
      }
    }
  },
  destroyed () {
    this.$store.commit('offer/clearOfficeItems')
    this.$store.commit('chat/vacancyQuestionAnswerList', [])
    this.$store.commit('offer/offerLaborerList', [])
    this.$store.commit('offer/setCurrentOfferLaborer', null)
  },
  async created () {
    // TODO проверить для админа
    await this.reloadOffer()

    this.eventsBus.$on(
      this.eventNames.ON_OFFER_RESPOND_LIST_INCREASED_NOTIFICATION, (data) => {
        if (this.isCurrentOfferNotification(data)) {
          this.updateOfferStatuses('respondNumber')
          this.updateRespondList()
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_RESPOND_CANCELLED_NOTIFICATION, (data) => {
        if (this.isCurrentOfferNotification(data)) {
          this.onOfferLaborerRejectOrCancel(data)
          this.updateOfferStatuses('respondNumber', true)
          this.updateOfferStatuses('rejectedNumber')
          // this.reloadOffer()
          this.updateRespondList()
          this.updateRejectList()
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_INVITATION_REJECTED_NOTIFICATION, (data) => {
        if (!this.isCurrentOfferNotification(data)) {
          return false
        }
        if (data.extra.resource.status === 'accepted') {
          this.reloadOffer()
        } else {
          this.onOfferLaborerRejectOrCancel(data)
          this.updateOfferStatuses('invitationNumber', true)
          this.updateOfferStatuses('rejectedNumber')
          // this.reloadOffer()
          this.updateRespondList()
          this.updateInvitationList()
          this.updateRejectList()
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_LABOR_ACCEPTED_OFFER_INVITATION_NOTIFICATION, async (data) => {
        if (this.isCurrentOfferNotification(data)) {
          const { extra: { laborer: { id: laborerId } } } = data

          if (
            this.offer.childrenNumber <= 1 ||
            (this.selectedUser && this.selectedUser.laborer && this.selectedUser.laborer.id === laborerId)
          ) {
            this.clearChat()
          }
          await this.reloadOffer()
        }
      }
    )
    this.eventsBus.$on(
      this.eventNames.ON_OFFER_WORKFLOW_SYSTEM_CANCEL_NOTIFICATION, (data) => {
        if (this.isCurrentOfferNotification(data)) {
          this.selectedUser = null
          this.$store.commit('offer/setCurrentOfferLaborer', null)
          this.$store.commit('offer/offerLaborerList', [])
          this.reloadOffer()
        }
      }
    )
  },
  beforeUpdate () {
    this.updateChatSize('offer-header-container')
  },
  updated () {
    this.updateChatSize('offer-header-container')
  },
  mounted () {
    this.$nextTick(function () {
      this.updateChatSize('offer-header-container')
    })
  },
  methods: {
    clearChat () {
      this.selectedUser = null
      this.$store.commit('offer/setCurrentOfferLaborer', null)
      this.$store.commit('offer/offerLaborerList', [])
      this.$store.commit('chat/vacancyQuestionAnswerList', [])
    },

    isCurrentOfferNotification (data) {
      const isOfferView = this.$route.name === routerList.OFFICE_OFFER_ID_NAME
      const offerId = this.$route.params.offerId
      if (isOfferView && offerId && this.offer && this.offer.id) {
        const { extra } = data
        let groupId = null
        if (extra.parent && extra.parent.id) {
          groupId = extra.parent.id
        }

        return data.parentId === this.offer.id || groupId === this.offer.id
      }

      return false
    },
    // updateChatSize () {
    //   setTimeout(() => {
    //     const elt = window.document.getElementById('main-container')
    //     const header = window.document.getElementById('offer-header-container')
    //     if (elt) {
    //       const height = elt.clientHeight
    //
    //       this.chatStyle = {
    //         height: `${height - (header.clientHeight + 60)}px`
    //       }
    //     }
    //   }, 2000)
    // },
    onOfferLaborerRejectOrCancel (data) {
      if (this.selectedUser && data.resourceId === this.selectedUser.id) {
        this.selectedUser = null
        this.$store.commit('offer/setCurrentOfferLaborer', null)
        this.$store.commit('offer/offerLaborerList',
          this.$store.state.offer.offerLaborerList.filter((item) => item.id !== data.resourceId)
        )
      }
    },
    updateOfferStatuses (key, removed = false) {
      if (removed) {
        this.offer[key] = parseInt(this.offer[key]) > 0 ? parseInt(this.offer[key]) - 1 : 0
      } else {
        this.offer[key] = parseInt(this.offer[key]) + 1
      }
    },
    pluriaizeOffers (offerNumber) {
      return pluriaizeOffers(offerNumber)
    },
    onCancelNumberChange (increase) {
      const max = this.offer.childrenNumber

      if (this.cancelOfferNumber < max && increase) {
        ++this.cancelOfferNumber
      } else if (!increase) {
        if (this.cancelOfferNumber > 1) {
          --this.cancelOfferNumber
        }
      }
    },
    async  reloadOffer () {
      this.$store.commit('offer/offerLaborerList', [])

      this.$store.dispatch('offer/fetchOfferById', { id: this.$route.params.offerId })
        .then((response) => {this.offer = AppOfferUtil.mapOffer(response.data)})
        .then(() => this.fetchOfferRespondent(true))
    },
    updateRespondList () {
      if (this.selectedStatus.value === 'respondNumber') {
        this.fetchOfferRespondent(true)
      }
    },
    updateInvitationList () {
      if (this.selectedStatus.value === 'invitationNumber') {
        this.fetchOfferInvitationsRespondent(true)
      }
    },
    updateRejectList () {
      if (this.selectedStatus.value === 'rejectedNumber') {
        this.fetchOfferRespondent(false)
        this.fetchOfferInvitationsRespondent(false)
      }
    },
    fetchOfferRespondent (statusGroup) {
      return this.$store.dispatch(
        'offer/fetchOfferRespondentById',
        {
          id: this.$route.params.offerId,
          statusGroup,
          requestRejected: this.selectedStatus.value === 'rejectedNumber'
        })
    },

    fetchOfferInvitationsRespondent (statusGroup) {
      return this.$store.dispatch('offer/fetchOfferInvitationsRespondentById',
        { officeId: this.$route.params.offerId, statusGroup })
    },

    selectStatus (status) {
      this.$store.commit('chat/vacancyQuestionAnswerList', [])
      this.selectedStatus = status
    },
    selectLaborer (laborer) {
      this.selectedUser = laborer
      this.$store.commit('chat/vacancyQuestionAnswerList', [])
      this.$store.commit('offer/setCurrentOfferLaborer', laborer)
    },
    toggleServerErrorDisplay () {
      this.serverError = null
      this.errorCode = null
    },
    showServerErrorMessage () {
      this.isLoading = false
      this.serverError = true
      setTimeout(() => {
        this.toggleServerErrorDisplay()
      }, this.serverErrorDisplayTimeOut)
    },
    onOfferItemChatUserCardReject (selectedUser) {
      this.selectedUser = selectedUser
      this.currentModal = modalName.rejectLaborer
    },
    async accept (responseLaborer) {
      try {
        this.isLoading = true
        await this.$store.dispatch('offer/fetchOfferRespondentAccept', {
          offerRespondId: responseLaborer.id
        })
        this.$store.commit('chat/vacancyQuestionAnswerList', [])
        this.currentModal = this.modalName.acceptLaborer
        this.$store.commit('offer/offerLaborerList', [])
        this.selectedUser = null
        this.$store.commit('offer/setCurrentOfferLaborer', null)
        this.isLoading = false
        await this.reloadOffer()
        this.updateRespondList()
        this.updateInvitationList()
      } catch (error) {
        if (error.status === 409) {
          this.updateRespondList()
          this.$store.commit('offer/setCurrentOfferLaborer', null)
          this.updateOfferStatuses('respondNumber', true)
        }

        this.errorCode = error.status
        this.isLoading = false
        this.serverError = true
        this.showServerErrorMessage()
      }
    },
    OncancelOffer () {
      this.cancelOfferNumber = 1
      this.currentModal = this.modalName.cancelOffer
    },
    async cancelOffer () {
      try {
        this.isLoading = true
        this.$store.commit('chat/vacancyQuestionAnswerList', [])
        this.$store.commit('offer/offerLaborerList', [])
        this.selectedUser = null
        this.$store.commit('offer/setCurrentOfferLaborer', null)
        await this.$store.dispatch(
          'offer/fetchOffersCancelById',
          { idList: [{ id: this.offer.id, canceledNumber: this.cancelOfferNumber }] }
        )
        this.$store.commit('offer/clearOfficeItems')
        await this.reloadOffer()
        this.closeModal()
        this.cancelOfferNumber = 1
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    async reject (responseLaborer) {
      try {
        this.isLoading = true
        await this.$store.dispatch(
          `offer/${
            this.selectedStatus.value === 'invitationNumber'
              ? 'fetchOfferInvitationCancel'
              : 'fetchOfferRespondentReject'
          }`
          , {
            offerInvitationId: responseLaborer.id
          })
        this.$store.commit('chat/vacancyQuestionAnswerList', [])
        this.$store.commit('offer/offerLaborerList', [])
        this.selectedUser = null
        this.$store.commit('offer/setCurrentOfferLaborer', null)
        this.isLoading = false
        await this.reloadOffer()
        this.currentModal = ''
        this.updateRespondList()
        this.updateInvitationList()
        this.updateRejectList()
      } catch (e) {
        this.isLoading = false
      }
    },

    closeModal () {
      this.currentModal = ''
    }
  }
}
</script>

<style lang="sass" scoped>
.cancel-description
  text-align: center
  @include fontStyle(500, 12px)
  opacity: .2
  margin-top: 10px
.offer-item
  padding-bottom: 0 !important
  &__left
    display: flex
    flex-direction: column
    width: 45%
    margin-right: 30px
    &__header__status
      display: flex
      margin: 15px 0
      &:first-of-type
        margin-top: 0
    &__list-user
      overflow: auto
      flex-grow: 1
      align-items: flex-start
      &_empty
        @include fontStyle(500, 16px)
        margin: 40px auto 0 auto
        color: rgba(0, 0, 0, 0.51)
  &__right
    width: 55%
    background-color: white
    height: 100%


</style>
